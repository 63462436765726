import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/store/services/jwt.service";
import store from "@/store";
import router from "@/router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://192.168.0.103:8000/api/v1/";
    Vue.axios.defaults.baseURL = "https://api.togohometg.com/api/v1/";
    // Vue.axios.defaults.baseURL = "https://apitest.togohometg.com/api/v1/";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  interceptRequest() {
    Vue.axios.interceptors.request.use((config) => {
      const authData = store.getters["isAuthenticated"];

      if (authData == null) {
        return config;
      }

      config.headers.common[
        "Authorization"
      ] = `bearer ${JwtService.getToken()}`;
      return config;
    });
  },

  interceptResponse() {
    Vue.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response) {
          if (
            error.response.status === 404 &&
            error.response.data.message === "Annonce non trouvé."
          ) {
            router.replace({ name: "NotFound" });
          }
          if (
            error.response.status === 401 &&
            originalRequest.url.includes("auth/refresh")
          ) {
            JwtService.destroyToken(); //store.commit("clearUserData");
            router.replace({ name: "Login" });
            return Promise.reject(error);
          } else if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await store.dispatch("refreshToken");
            return axios(originalRequest);
          }
        } else {
          console.log("Une erreur de response");
        }

        return Promise.reject(error);
      }
    );
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
