import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    contacts: [],
  },
  getters: {
    getContacts(state) {
      return state.contacts;
    },
  },
  actions: {
    ALL_CONTACT(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`about`)
            .then(({ data }) => {
              resolve(data.about);
              context.commit("setContact", data.about);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setContact(state, contacts) {
      state.contacts = contacts;
    },
  },
};
