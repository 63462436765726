const authRoute = [
  {
    path: "/inscription",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register"*/ "@/views/auth/Register"),
    meta: {
      requiresAuth: false,
      title: "S'inscrire sur TOGOHOME",
    },
  },
  {
    path: "/connexion",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login"*/ "@/views/auth/Login"),
    meta: {
      requiresAuth: false,
      title: "Se connecter a mon compte",
    },
  },
  {
    path: "/renvoyer/code",
    name: "sendBack",
    component: () =>
      import(/* webpackChunkName: "SendBack"*/ "@/views/auth/SendBack"),
    meta: {
      requiresAuth: false,
      title: "Renvoyer un nouveau code",
    },
  },
  {
    path: "/confirmation/account",
    name: "confirm",
    component: () =>
      import(/* webpackChunkName: "Confirmation"*/ "@/views/auth/Confirmation"),
    meta: {
      requiresAuth: false,
      title: "Confirmer son compte",
      sendType: "activeAccount",
    },
  },
  {
    path: "/confirmation/account/reset/password",
    name: "confirmPassword",
    component: () =>
      import(
        /* webpackChunkName: "PasswordConfirmation"*/ "@/views/auth/Confirmation"
      ),
    meta: {
      requiresAuth: false,
      title: "Confirmer son compte",
      sendType: "forgotPassword",
    },
  },
  {
    path: "/forgot/password",
    name: "Forgot",
    component: () =>
      import(/* webpackChunkName: "ForgotPassword"*/ "@/views/auth/Forgot"),
    meta: {
      requiresAuth: false,
      title: "Mots de passe oublier",
    },
  },
  {
    path: "/reset/password",
    name: "Reset",
    component: () =>
      import(/* webpackChunkName: "Reset"*/ "@/views/auth/Reset"),
    meta: {
      requiresAuth: false,
      title: "restorer mot de passe",
    },
  },
  {
    path: "/polities/confidentiality",
    name: "Polities",
    component: () =>
      import(/* webpackChunkName: "Condition"*/ "@/views/auth/Condition"),
    meta: {
      userAuth: true,
      title: "Conditions d'utilisations & politique de confidentialité",
    },
  },
  {
    path: "/lafti/presentation",
    name: "Lafti",
    component: () =>
      import(/* webpackChunkName: "Lafti"*/ "@/views/auth/FtiPage"),
    meta: {
      title: "Présentation de la FTI",
    },
  },
];

export default authRoute;
