import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    listServiceRequest: [],
    listVisitRequest: [],
  },
  getters: {
    getListServiceRequest(state) {
      return state.listServiceRequest;
    },
    getListVisitRequest(state) {
      return state.listVisitRequest;
    },
  },
  actions: {
    serviceRequest(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("auth/demande", payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    visitRequest(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("auth/demande/visite", payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    serviceRequestList(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/mes-demandes-services`)
            .then((response) => {
              resolve(response);
              context.commit(
                "setListServiceRequest",
                response.data.demandeServices
              );
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    deleteServiceRequest(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.delete(`auth/demande/${payload}`)
            .then((response) => {
              resolve(response);
              context.dispatch("serviceRequestList");
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    visitRequestList(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/liste-des-visites`)
            .then((response) => {
              resolve(response);
              context.commit("setListVisitRequest", response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    deleteVisitRequest(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.delete(`auth/visit/${payload}`)
            .then((response) => {
              resolve(response);
              context.dispatch("visitRequestList");
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setListServiceRequest(state, listServiceRequest) {
      state.listServiceRequest = listServiceRequest;
    },
    setListVisitRequest(state, listVisitRequest) {
      state.listVisitRequest = listVisitRequest;
    },
  },
};
