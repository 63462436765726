const homeRoute = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home"*/ "@/views/Home"),
    meta: {
      title: "Le Google de l’immobilier",
      userAuth: true,
    },
  },
  {
    path: "/location",
    name: "ad.rent",
    component: () =>
      import(/* webpackChunkName: "Location"*/ "@/views/ad/rent/Index"),
    meta: {
      title: "Location",
      userAuth: true,
    },
  },
  {
    path: "/location/hotel",
    name: "rent.hotel",
    component: () =>
      import(/* webpackChunkName: "Hotels"*/ "@/views/product/Hotels"),
    meta: {
      title: "Hotêls",
      userAuth: true,
    },
  },
  {
    path: "/location/furniture",
    name: "rent.furniture",
    component: () =>
      import(/* webpackChunkName: "Furniture"*/ "@/views/ad/rent/Furniture"),
    meta: {
      title: "Location meublés",
      userAuth: true,
    },
  },
  {
    path: "/vendre",
    name: "ad.sell",
    component: () =>
      import(/* webpackChunkName: "Sell"*/ "@/views/ad/sell/Index"),
    meta: {
      title: "Vente",
      userAuth: true,
    },
  },
  {
    path: "/annonce/vip",
    name: "ad.vip",
    component: () => import(/* webpackChunkName: "VIP"*/ "@/views/ad/Vip"),
    meta: {
      title: "V.I.P",
      userAuth: true,
    },
  },
  {
    path: "/annonce/:id",
    name: "ad.show",
    component: () => import(/* webpackChunkName: "AdShow"*/ "@/views/ad/Show"),
    meta: {
      title: "Voir annonce",
      userAuth: true,
    },
  },
  {
    path: "/service",
    name: "Service",
    component: () =>
      import(/* webpackChunkName: "Services"*/ "@/views/service/Index"),
    meta: {
      title: "Services",
      userAuth: true,
    },
  },
  {
    path: "/service/:id",
    name: "Service.show",
    component: () =>
      import(/* webpackChunkName: "ServicesShow"*/ "@/views/service/Show"),
    meta: {
      title: "Voir service",
      userAuth: true,
    },
  },
  {
    path: "/partner",
    name: "Partner",
    component: () =>
      import(/* webpackChunkName: "Partner"*/ "@/views/partner/Index"),
    meta: {
      title: "Partenaires",
      userAuth: true,
    },
  },
  {
    path: "/info/partner/:id",
    name: "Partner.show",
    component: () =>
      import(/* webpackChunkName: "PartnerShow"*/ "@/views/partner/Show"),
    meta: {
      title: "Voir partenaire",
      userAuth: true,
    },
  },
  {
    path: "/posts",
    name: "post",
    component: () =>
      import(/* webpackChunkName: "Posts"*/ "@/views/post/Index"),
    meta: {
      title: "Blog",
      userAuth: true,
    },
  },
  {
    path: "/post/:id",
    name: "post.show",
    component: () =>
      import(/* webpackChunkName: "PostShow"*/ "@/views/post/Show"),
    meta: {
      title: "Voir article",
      userAuth: true,
    },
  },
  {
    path: "/produit/:id",
    name: "show.product",
    component: () =>
      import(/* webpackChunkName: "ProductShow"*/ "@/views/product/Show"),
    meta: {
      title: "Voir produit",
      userAuth: true,
    },
  },
  {
    path: "/diaspora",
    name: "diaspora",
    component: () =>
      import(/* webpackChunkName: "Diaspora"*/ "@/views/Diaspora"),
    meta: {
      title: "Diaspora",
      userAuth: true,
    },
  },
  {
    path: "/rechercher/annonce",
    name: "search",
    component: () => import(/* webpackChunkName: "Search"*/ "@/views/Search"),
    meta: {
      title: "Recherche",
      userAuth: true,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import(/* webpackChunkName: "Contact"*/ "@/views/Contact"),
    meta: {
      title: "Contact",
      userAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "About"*/ "@/views/About"),
    meta: {
      title: "A propos de TOGOHOME",
      userAuth: true,
    },
  },
];

export default homeRoute;
