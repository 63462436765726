import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    popularTown: [],
  },
  getters: {
    getPopularTown(state) {
      return state.popularTown;
    },
  },
  actions: {
    ALL_POPULAR_TOWNS(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("town/popular-with-ad")
            .then((response) => {
              resolve(response.data.data);
              context.commit("setPopularTown", response.data.data);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setPopularTown(state, popularTown) {
      state.popularTown = popularTown;
    },
  },
};
