import Vue from "vue";
import {
  IconsPlugin,
  BNav,
  BNavbar,
  BButton,
  BSpinner,
  BTable,
  BPagination,
  BNavbarBrand,
  BNavbarToggle,
  BNavItem,
  BNavText,
  BNavbarNav,
  BCollapse,
  BFormSelect,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
//NavBar components
Vue.component("BNav", BNav);
Vue.component("b-navbar", BNavbar);
Vue.component("b-navbar-brand", BNavbarBrand);
Vue.component("b-nav-item", BNavItem);
Vue.component("b-navbar-toggle", BNavbarToggle);
Vue.component("b-nav-text", BNavText);
Vue.component("b-navbar-nav", BNavbarNav);
Vue.component("b-collapse", BCollapse);
//Form components
Vue.component("b-form-select", BFormSelect);
Vue.component("b-form-select", BFormSelect);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-checkbox", BFormCheckbox);

Vue.component("b-button", BButton);
Vue.component("b-spinner", BSpinner);
Vue.component("b-table", BTable);
Vue.component("b-pagination", BPagination);

const $ = import("jquery");
window.$ = $;
const bootstrap = import("bootstrap");
window.bootstrap = bootstrap;
