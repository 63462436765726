import ApiService from "../services/api.service";

export default {
  namespace: true,
  state: {
    pubs: [],
  },
  getters: {
    getPubs(state) {
      return state.pubs;
    },
  },
  actions: {
    allPublicity(context) {
      return new Promise((resolve, reject) => {
        ApiService.get("publicite")
          .then((response) => {
            resolve(response);
            context.commit("setPubs", response.data.publicites);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    setPubs(state, pubs) {
      state.pubs = pubs;
    },
  },
};
