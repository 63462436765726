import Vue from "vue";

Vue.mixin({
  methods: {
    getDays(datetime) {
      if (datetime) {
        let now = new Date();
        let adCreatedDate = new Date(datetime);
        let timeDiff = now.getTime() - adCreatedDate.getTime();

        return Math.round(timeDiff / (1000 * 3600 * 24));
      } else return 0;
    },
    setBooleanValue(data) {
      if (data) {
        return 1;
      } else {
        return 0;
      }
    },
    showPassword(el) {
      if (el.type === "password") {
        el.type = "text";
      } else {
        el.type = "password";
      }
    },
  },
});
