import router from "../router";
import store from "../store";

router.beforeEach((to, from, next) => {
  if (to.meta.userAuth || to.meta.requiresAuth || !to.meta.requiresAuth) {
    next();
  }

  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    return next({ name: "Login" });
  } else if (
    !(to.meta.requiresAuth || to.meta.userAuth) &&
    store.getters.isAuthenticated
  ) {
    return next({ name: "dashboard" });
  }
});
router.afterEach((to, from) => {
  if (
    (to.meta.requiresAuth || to.meta.userAuth) &&
    store.getters.isAuthenticated
  ) {
    store.dispatch("USER").then((response) => {
      if (
        response.data.data.type_compte === "externe" &&
        response.data.data.roles.length !== 0
      ) {
        return;
      } else {
        store.dispatch("LOGOUT").then(() => {
          return from({ name: "forbidden" });
        });
      }
    });
  }
});
