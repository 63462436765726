import Vue from "vue";
import VueRouter from "vue-router";
import homeRoute from "@/router/home.route";
import authRoute from "@/router/auth.route";
import adminRoute from "@/router/admin.route";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "BaseLayout",
    redirect: "Home",
    component: () =>
      import(/* webpackChunkName: "BaseLayout"*/ "@/views/Layout/BaseLayout"),
    children: homeRoute,
  },
  {
    path: "/auth",
    name: "AuthLayout",
    redirect: "Register",
    component: () =>
      import(/* webpackChunkName: "AuthLayout"*/ "@/views/Layout/AuthLayout"),
    children: authRoute,
  },
  {
    path: "/admin",
    name: "AdminLayout",
    redirect: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "DashBaseLayout"*/ "@/views/Layout/DashBaseLayout"
      ),
    children: adminRoute,
  },
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound"*/ "@/views/errors/NotFound"),
  },
  {
    path: "/403/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webpackChunkName: "Forbidden"*/ "@/views/errors/Forbidden"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
