import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    partners: [],
    partner: {
      categorie: {},
      zones: [],
    },
    partnerProducts: [],
    formPartner: {
      user: {},
      partenaire: {},
    },
    optionPartner: {
      zones: [],
      categories: [],
    },
  },
  getters: {
    getPartners(state) {
      return state.partners;
    },
    getPartner(state) {
      return state.partner;
    },
    getPartnerProducts(state) {
      return state.partnerProducts;
    },
    getFormPartner(state) {
      return state.formPartner;
    },
    getOptionPartner(state) {
      return state.optionPartner;
    },
  },
  actions: {
    ALL_PARTNERS(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("partener")
            .then((response) => {
              resolve(response.data.partners);
              context.commit("setPartners", response.data.partners);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    SINGLE_PARTNER(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`partener/${payload}`)
            .then((response) => {
              resolve(response.data.data);
              context.commit("setPartner", response.data.data);
              context.commit("setPartnerProducts", response.data.data.products);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    editPartner(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/partener/${payload}/edit`)
            .then((response) => {
              resolve(response.data.data);
              context.commit("setFormPartner", response.data.form);
              context.commit("setOptionPartner", response.data.option);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    updatePartner(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post(
            `auth/partener/${payload.id}`,
            payload.form,
            payload.config
          )
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setPartners(state, partners) {
      state.partners = partners;
    },
    setPartner(state, partner) {
      state.partner = partner;
    },
    setPartnerProducts(state, products) {
      state.partnerProducts = products;
    },
    setFormPartner(state, formPartner) {
      state.formPartner = formPartner;
    },
    setOptionPartner(state, optionPartner) {
      state.optionPartner = optionPartner;
    },
  },
};
