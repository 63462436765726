import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import actions from "./actions";
import createLogger from "vuex/dist/logger";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {},
  getters: {},
  actions,
  modules,
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
});
