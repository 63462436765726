import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    listAnnonceOffers: [],
    listProductOffers: [],
  },
  getters: {
    getListAnnonceOffers(state) {
      return state.listAnnonceOffers;
    },
    getListProductOffers(state) {
      return state.listProductOffers;
    },
  },
  actions: {
    offerAnnonce(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("auth/realestate/proposal", payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    offerProduct(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("auth/proposition/offre", payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    annonceOfferList(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/liste-de-mes-propositions`)
            .then((response) => {
              resolve(response);
              context.commit("setListAnnonceOffers", response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    deleteAnnonceOffer(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.delete(`auth/realestate/proposal/${payload}`)
            .then((response) => {
              resolve(response);
              context.dispatch("annonceOfferList");
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    productOfferList(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/mes-propositons-produits`)
            .then((response) => {
              resolve(response);
              context.commit(
                "setListProductOffers",
                response.data.propositions
              );
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    deleteProductOffer(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.delete(`auth/product/proposal/${payload}`)
            .then((response) => {
              resolve(response);
              context.dispatch("productOfferList");
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    rejectOffreProduct(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.put(`auth/product/proposal/${payload.id}/finish`, {
            motif: payload.motif,
          })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    acceptOfferProduct(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/product/proposal/${payload}/accept`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setListAnnonceOffers(state, listAnnonceOffers) {
      state.listAnnonceOffers = listAnnonceOffers;
    },
    setListProductOffers(state, listProductOffers) {
      state.listProductOffers = listProductOffers;
    },
  },
};
