const adminRoute = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard"*/ "@/views/admin/dashboard/Index"
      ),
    meta: {
      requiresAuth: true,
      title: "Tableau de bord",
    },
  },
  // ########################################### START MODULE AD ###################################################
  {
    path: "/annonces",
    name: "ad.list",
    component: () =>
      import(/* webpackChunkName: "AdminAnnonces"*/ "@/views/admin/ad/Index"),
    meta: {
      requiresAuth: true,
      title: "Liste de mes annonces",
      role: "agentimmobilier",
    },
  },
  {
    path: "/nouvelle/annonces",
    name: "ad.new",
    component: () =>
      import(/* webpackChunkName: "StoreAnnonce"*/ "@/views/admin/ad/Form"),
    meta: {
      requiresAuth: true,
      back: "/annonces",
      title: "Ajouter une annonce",
      mode: "create",
      role: "agentimmobilier",
    },
  },
  {
    path: "/modifier/annonces/:id",
    name: "ad.edit",
    component: () =>
      import(/* webpackChunkName: "UpdateAnnonce"*/ "@/views/admin/ad/Form"),
    meta: {
      requiresAuth: true,
      back: "/annonces",
      title: "Modifier une annonce",
      mode: "update",
      role: "agentimmobilier",
    },
  },
  {
    path: "/ad/:id",
    name: "admin.ad.show",
    component: () =>
      import(/* webpackChunkName: "AdminAnnonceShow"*/ "@/views/admin/ad/Show"),
    meta: {
      requiresAuth: true,
      title: "Détail sur l'annonce",
      role: "agentimmobilier",
    },
  },

  // ########################################### END MODULE AD ###################################################

  // ########################################### START MODULE PRODUCT ###################################################

  {
    path: "/produits",
    name: "product.list",
    component: () =>
      import(
        /* webpackChunkName: "AdminProducts"*/ "@/views/admin/product/Index"
      ),
    meta: {
      requiresAuth: true,
      title: "Liste de mes produits",
      role: "partner",
    },
  },
  {
    path: "/nouveau/produit",
    name: "product.new",
    component: () =>
      import(
        /* webpackChunkName: "StoreProduct"*/ "@/views/admin/product/Form"
      ),
    meta: {
      requiresAuth: true,
      back: "/produits",
      title: "Ajouter un produit",
      mode: "create",
      role: "partner",
    },
  },
  {
    path: "/modifier/produit/:id",
    name: "product.edit",
    component: () =>
      import(
        /* webpackChunkName: "UpdateProduct"*/ "@/views/admin/product/Form"
      ),
    meta: {
      requiresAuth: true,
      back: "/produits",
      title: "Modifier un produit",
      mode: "update",
      role: "partner",
    },
  },
  {
    path: "/mon/produit/:id",
    name: "product.show",
    component: () =>
      import(
        /* webpackChunkName: "AdminProductShow"*/ "@/views/admin/product/Show"
      ),
    meta: {
      requiresAuth: true,
      title: "Détail sur le produit",
      role: "partner",
    },
  },

  // ########################################### END MODULE PRODUCT ###################################################

  // ########################################### START MODULE VISIT ###################################################

  {
    path: "/annonces/visites",
    name: "visit.list",
    component: () =>
      import(
        /* webpackChunkName: "VisitAnnonces"*/ "@/views/admin/visit/Index"
      ),
    meta: {
      requiresAuth: true,
      title: "Liste de mes visites",
    },
  },
  {
    path: "/listes/visites",
    name: "user.visit",
    component: () =>
      import(/* webpackChunkName: "Visits"*/ "@/views/admin/visit/user/Index"),
    meta: {
      requiresAuth: true,
      title: "Liste de mes visites",
    },
  },

  // ########################################### END MODULE VISIT ###################################################

  // ########################################### START MODULE OFFER ###################################################

  {
    path: "/offre/annonce/",
    name: "offer.ad",
    component: () =>
      import(/* webpackChunkName: "OfferAnnonce"*/ "@/views/admin/offer/Index"),
    meta: {
      requiresAuth: true,
      title: "Liste de mes offres sur annonce",
      type: "ad",
    },
  },
  {
    path: "/offre/product/:id",
    name: "offer.product",
    component: () =>
      import(/* webpackChunkName: "OfferProduct"*/ "@/views/admin/offer/Index"),
    meta: {
      requiresAuth: true,
      title: "Liste de mes offres sur produit",
      type: "product",
    },
  },
  {
    path: "/offre/produit",
    name: "user.product",
    component: () =>
      import(
        /* webpackChunkName: "OfferUserProduct"*/ "@/views/admin/offer/user/product/Index"
      ),
    meta: {
      requiresAuth: true,
      title: "Liste de mes offres sur produit",
      type: "product",
    },
  },
  {
    path: "/offre/annonce",
    name: "user.ad",
    component: () =>
      import(
        /* webpackChunkName: "OfferUserAnnonce"*/ "@/views/admin/offer/user/ad/Index"
      ),
    meta: {
      requiresAuth: true,
      title: "Liste de mes offres sur annonce",
      type: "ad",
    },
  },

  // ########################################### END MODULE OFFER ###################################################

  // ########################################### END MODULE SERVICES ###################################################
  {
    path: "/demande/services",
    name: "service.list",
    component: () =>
      import(
        /* webpackChunkName: "ServiceRequests"*/ "@/views/admin/service/Index"
      ),
    meta: {
      requiresAuth: true,
      title: "Liste de mes demandes de service",
    },
  },
  // ########################################### START MODULE SERVICES ###################################################

  // ########################################### START MODULE PROFILE ###################################################

  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "Profile"*/ "@/views/admin/user/Index"),
    meta: {
      requiresAuth: true,
      title: "Mon profil",
    },
  },
  {
    path: "/modifier/profile",
    name: "profile.update",
    component: () =>
      import(
        /* webpackChunkName: "UpdateProfile"*/ "@/views/admin/user/ProfileForm"
      ),
    meta: {
      requiresAuth: true,
      back: "/profile",
      title: "Modifier mon profil",
    },
  },

  // ########################################### END MODULE PROFILE ###################################################

  // ########################################### START MODULE PARTNER ###################################################

  {
    path: "/profile/partenaire",
    name: "partner.profile",
    component: () =>
      import(
        /* webpackChunkName: "PartnerProfile"*/ "@/views/admin/partner/Index"
      ),
    meta: {
      requiresAuth: true,
      title: "Profil partenaire",
    },
  },
  {
    path: "/modifier/profile/partenaire",
    name: "partner.update",
    component: () =>
      import(
        /* webpackChunkName: "UpdatePartnerProfile"*/ "@/views/admin/partner/Form"
      ),
    meta: {
      requiresAuth: true,
      back: "/profile/partenaire",
      title: "Modifier mes imformations",
    },
  },

  // ########################################### END MODULE PARTNER ###################################################
];

export default adminRoute;
