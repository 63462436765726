export default {
  namespace: true,
  state: {
    town: null,
    category: null,
    type: null,
    district: null,
    regionTowns: [],
    townDistricts: [],
    selectedRegion: {},
    furnitureRegionSelect: null,
    regionName: "",
  },
  getters: {
    GET_TOWN(state) {
      return state.town;
    },
    GET_CATEGORY(state) {
      return state.category;
    },
    GET_TYPE(state) {
      return state.type;
    },
    GET_DISTRICT(state) {
      return state.district;
    },
    getRegionTown(state) {
      return state.regionTowns;
    },
    getTownDistricts(state) {
      return state.townDistricts;
    },
    getSelectedRegion(state) {
      return state.selectedRegion;
    },
    getFurnitureRegionSelectTown(state) {
      return state.furnitureRegionSelect;
    },
    getRegionName(state) {
      return state.regionName;
    },
  },
  actions: {
    SEARCH_TOWN_TYPE(context, payload) {
      context.commit("SET_TOWN", payload.town);
      context.commit("SET_CATEGORY", payload.category);
    },
    SEARCH_TOWN_TYPE_CATEGORY(context, payload) {
      context.commit("SET_TOWN", payload.town);
      context.commit("SET_CATEGORY", payload.category);
      context.commit("SET_TYPE", payload.type);
    },
    SEARCH_TOWN(context, payload) {
      context.commit("SET_TOWN", payload.town);
    },
    searchCategory(context, payload) {
      context.commit("SET_CATEGORY", payload.category);
    },
    SEARCH_TOWN_DISTRICT(context, payload) {
      context.commit("SET_TOWN", payload.town);
      context.commit("SET_DISTRICT", payload.district);
    },
    SEARCH_RESET(context) {
      context.commit("SET_NULL_DATA");
    },
    findTownByRegion(context, payload) {
      return new Promise((resolve) => {
        let options = context.getters.getFilterOptions;
        let regions = context.getters.getFilterRegion;
        if (payload) {
          let region = regions.filter((region) => {
            return region.nom === payload;
          });
          let regionId = region[0].id;
          let towns = options.villes.filter((town) => {
            return parseInt(regionId) === parseInt(town.region_id);
          });
          context.commit("setRegionTown", towns);
          context.commit("setSelectedRegion", region);
          resolve(towns);
        } else return null;
      });
    },
  },
  mutations: {
    SET_TOWN(state, town) {
      return (state.town = town);
    },
    SET_CATEGORY(state, category) {
      return (state.category = category);
    },
    SET_TYPE(state, type) {
      return (state.type = type);
    },
    SET_DISTRICT(state, district) {
      return (state.district = district);
    },
    SET_NULL_DATA(state) {
      state.type = null;
      state.town = null;
      state.category = null;
      state.district = null;
      state.regionTowns = [];
      state.townDistricts = [];
      state.selectedRegion = {};
    },
    setRegionTown(state, regionTowns) {
      state.regionTowns = regionTowns;
    },
    setTownDistricts(state, townDistricts) {
      state.townDistricts = townDistricts.quartiers;
    },
    setSelectedRegion(state, selectedRegion) {
      state.selectedRegion = selectedRegion[0];
    },
    setSelectRegion(state, furnitureRegionSelect) {
      state.furnitureRegionSelect = furnitureRegionSelect.villes;
      state.regionName = furnitureRegionSelect.nom;
    },
    setNullSelectRegion(state) {
      state.furnitureRegionSelect = null;
      state.regionName = "";
    },
  },
};
