import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    services: [],
    service: {},
    diasporaServices: [],
  },
  getters: {
    getServices(state) {
      return state.services;
    },
    getService(state) {
      return state.service;
    },
    getDiasporaServices(state) {
      return state.diasporaServices;
    },
  },
  actions: {
    ALL_SERVICES(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("service")
            .then((response) => {
              resolve(response.data.data);
              context.commit("setServices", response.data.services);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    SINGLE_SERVICE(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`service/${payload}`)
            .then((response) => {
              context.commit("setService", response.data.service);
              resolve(response.data.service);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
    servicesDisopra(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("services")
            .then((response) => {
              resolve(response);
              context.commit("setDiasporaServices", response.data.services);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur s'est produit, veuillez réessayer plus tard. Merci!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merci!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setServices(state, services) {
      state.services = services;
    },
    setService(state, service) {
      state.service = service;
    },
    setDiasporaServices(state, diasporaServices) {
      state.diasporaServices = diasporaServices;
    },
  },
};
