window.$ = require("jquery");

import VueWait from "vue-wait";
import Vue from "vue";
import router from "./router";
import store from "./store";
import ApiService from "./store/services/api.service";

import "@/plugins";
import "@/directive";
import "@/mixin";
import "@/middleware";
import "hooper/dist/hooper.css";
import "semantic-ui-css/semantic.min.css";

const App = () => import("./App.vue");

ApiService.init();
ApiService.interceptRequest();
ApiService.interceptResponse();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  wait: new VueWait({
    useVuex: true, // You must pass this option `true` to use Vuex
    vuexModuleName: "vuex-example-module", // It's optional, `wait` by default.
  }),
  render: (h) => h(App),
}).$mount("#app");
