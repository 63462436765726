import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import { Marker, Map } from "vue2-google-maps";

Vue.component("GmapMarker", Marker);
Vue.component("GmapMap", Map);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDWq4DqFb97cLQVADNw5mLQ1ixaEcG6Ym8",
    libraries: "places",
    v: "3.26",
  },
  installComponents: false,
});
